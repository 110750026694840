<template>
  <Page>
    <template v-slot:content>
      <Filters
        :initial-filters="initialFilters"
        @onFiltersChanged="onFiltersChanged"
      />
      <v-data-iterator
        :footer-props="table.footerProps"
        :items="items"
        :loading="table.loading"
        :options.sync="table.options"
        :server-items-length="totalItems"
      >
        <template v-slot:loading>
          <v-sheet
            class="text-center pa-6"
            elevation="3"
            rounded
          >
            <div class="text-center mb-4">
              <v-progress-linear
                indeterminate
                rounded
                height="6"
              />
            </div>
            {{ $t('global.loading') }}
          </v-sheet>
        </template>
        <template v-slot:no-data>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.no-data') }}
          </v-sheet>
        </template>
        <template v-slot="props">
          <v-sheet
            elevation="3"
            rounded
            class="mb-4"
          >
            <v-container fluid>
              <v-row dense>
                <v-col
                  cols="12"
                >
                  <v-checkbox
                    v-model="selectAll"
                    :label="$t('views.external_catalog.synchro_lines.forms.input.select_all')"
                    dense
                    hide-details
                    @change="checkSelectAll($event)"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-alert
                    v-if="nbSelectedProducts > 0"
                    type="info"
                    class="mt-6"
                  >
                    <v-row>
                      <v-col>
                        {{
                          $t(
                            'views.external_catalog.synchro_lines.forms.message.selection',
                            {
                              'selected': nbSelectedProducts,
                              'total': totalItems,
                            }
                          )
                        }}
                      </v-col>
                      <v-col class="d-flex">
                        <v-spacer />
                        <v-dialog
                          v-model="deleteDialog"
                          max-width="600px"
                          @click:outside="cancel"
                          @keydown.esc="cancel"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="white--text mr-6"
                              color="red darken-3"
                              :loading="deleteLoading"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ $t('views.external_catalog.synchro_lines.forms.input.delete') }}
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title />
                            <v-card-text>
                              <v-row>
                                <v-col>
                                  <v-alert
                                    type="error"
                                    outlined
                                  >
                                    {{
                                      $t('views.external_catalog.synchro_lines.forms.dialog.deletion.text', {
                                        'selected': nbSelectedProducts
                                      })
                                    }}
                                  </v-alert>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                outlined
                                color="red darken-2"
                                @click="cancel"
                              >
                                <v-icon left>
                                  mdi-cancel
                                </v-icon>
                                {{ $t('views.external_catalog.synchro_lines.forms.dialog.deletion.cancel') }}
                              </v-btn>
                              <v-spacer />
                              <v-btn
                                raised
                                color="red darken-1"
                                class="white--text"
                                @click="deleteSelection"
                              >
                                <v-icon left>
                                  mdi-delete
                                </v-icon>
                                {{ $t('views.external_catalog.synchro_lines.forms.dialog.deletion.ok') }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog
                          v-model="importDialog"
                          max-width="600px"
                          @click:outside="cancel"
                          @keydown.esc="cancel"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="white--text"
                              color="blue darken-3"
                              :loading="importLoading"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ $t('views.external_catalog.synchro_lines.forms.input.import') }}
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="text-h5">
                                {{ $t('views.external_catalog.synchro_lines.forms.dialog.import.title') }}
                              </span>
                            </v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col>
                                  <v-alert
                                    type="warning"
                                    outlined
                                  >
                                    {{ $t('views.external_catalog.synchro_lines.forms.dialog.import.text',
                                          { 'selected': nbSelectedProducts })
                                    }}
                                  </v-alert>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="12"
                                  class="py-1"
                                >
                                  Appliquer le(s) drop(s):
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="12"
                                  class="py-1"
                                >
                                  <DropsSelector v-model="dropIds" />
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="12"
                                >
                                  <v-switch
                                    v-model="directToCuration"
                                    label="Envoyer directement en modération ?"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                outlined
                                color="red darken-2"
                                @click="cancel"
                              >
                                <v-icon left>
                                  mdi-cancel
                                </v-icon>
                                {{ $t('views.external_catalog.synchro_lines.forms.dialog.import.cancel') }}
                              </v-btn>
                              <v-spacer />
                              <v-btn
                                raised
                                color="green darken-1"
                                class="white--text"
                                @click="importSelection"
                              >
                                <v-icon left>
                                  mdi-import
                                </v-icon>
                                {{ $t('views.external_catalog.synchro_lines.forms.dialog.import.ok') }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="2"
            >
              <v-card>
                <v-card-actions>
                  <v-checkbox
                    v-model="selectionOverriden[item.id]"
                    dense
                    hide-details
                  />
                </v-card-actions>
                <div class="image-wrapper white--text align-end">
                  <v-carousel hide-delimiters>
                    <template v-if="item.external_product.images.length">
                      <v-carousel-item
                        v-for="(image, index) in item.external_product.images"
                        :key="item.id + '-img' + index"
                        :src="image"
                        cover
                      />
                    </template>
                    <template v-else>
                      <v-carousel-item
                        :key="item.id + '-img-default'"
                        src="../../../assets/no-image.webp"
                        cover
                      />
                    </template>
                  </v-carousel>
                  <v-card-title class="product-title font-weight-bold">
                    {{ item.external_product.title.fr }}
                  </v-card-title>
                </div>
                <v-divider />
                <v-card-subtitle>
                  <v-row
                    align="center"
                  >
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      xs="12"
                    >
                      <v-chip
                        v-if="item.import_status !== 'none'"
                        :color="statusColor(item)"
                        text-color="white"
                        class="ma-2"
                        label
                        small
                      >
                        {{
                          $t(
                            'views.external_catalog.synchro_lines.forms.labels.import',
                            {
                              'status': $t(
                                `views.external_catalog.synchro_lines.enum.import_status.${item.import_status}`
                              )
                            }
                          )
                        }}
                      </v-chip>
                      <v-icon
                        v-if="item.processing"
                        color="green darken-2"
                        :title="$t('views.external_catalog.synchro_lines.forms.labels.processing')"
                      >
                        mdi-sync
                      </v-icon>
                      <v-icon
                        v-else-if="item.dirty && item.import_status !== 'none'"
                        color="orange darken-2"
                        :title="$t('views.external_catalog.synchro_lines.forms.labels.dirty')"
                      >
                        mdi-sync-alert
                      </v-icon>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      xs="12"
                    >
                      <div class="text-right">
                        <span
                          v-if="item.external_product.nb_variants === 1"
                          class="text-h6"
                        >
                          {{ item.external_product.price.min|currency }}
                        </span>
                        <template v-else>
                          À partir de <span class="text-h6">{{ item.external_product.price.min|currency }}</span>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
                <v-card-text>
                  <v-simple-table dense>
                    <tbody>
                      <tr>
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.reference') }}</td>
                        <td>{{ item.external_product.reference }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.provider') }}</td>
                        <td>{{ item.provider.name }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.variants') }}</td>
                        <td>{{ item.external_product.nb_variants }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.quantity') }}</td>
                        <td>{{ item.external_product.quantity }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.category') }}</td>
                        <td>
                          <template v-if="item.external_product.category">
                            <em v-if="!item.provider.has_external_mapping">
                              {{ item.external_product.category }}
                            </em>
                            <span
                              v-else-if="item.mapping.category[item.external_product.category]?.internal_id"
                              style="white-space: nowrap;"
                            >
                              {{ item.mapping.category[item.external_product.category].internal_name }}
                              <v-icon
                                color="green"
                                small
                              >
                                mdi-check
                              </v-icon>
                            </span>
                            <span
                              v-else
                              style="white-space: nowrap;"
                            >
                              <em>{{ item.external_product.category }}</em>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    color="orange"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-alert
                                  </v-icon>
                                </template>
                                <span>Valeur non mappée</span>
                              </v-tooltip>
                            </span>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.sizes') }}</td>
                        <td>
                          <div
                            v-for="(variant, index) in item.external_product.variants"
                            :key="index"
                          >
                            <div
                              v-for="(size, index2) in variant.sizes"
                              :key="index2"
                            >
                              <em v-if="!item.provider.has_external_mapping">
                                {{ size }}
                              </em>
                              <span v-else-if="item.mapping.taxonomy[size]?.internal_id">
                                {{ item.mapping.taxonomy[size].internal_name }}
                                <v-icon
                                  color="green"
                                  small
                                >
                                  mdi-check
                                </v-icon>
                              </span>
                              <span v-else>
                                <em>{{ size }} ({{ variant.eanCode||variant.sku }})</em>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      small
                                      color="orange"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-alert
                                    </v-icon>
                                  </template>
                                  <span>Valeur non mappée</span>
                                </v-tooltip>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.brand') }}</td>
                        <td>
                          <template v-if="item.external_product.brand">
                            <em v-if="!item.provider.has_external_mapping">
                              {{ item.external_product.brand }}
                            </em>
                            <span
                              v-else-if="item.mapping.taxonomy[item.external_product.brand]?.internal_id"
                              style="white-space: nowrap;"
                            >
                              {{ item.mapping.taxonomy[item.external_product.brand].internal_name }}
                              <v-icon
                                color="green"
                                small
                              >
                                mdi-check
                              </v-icon>
                            </span>
                            <span
                              v-else
                              style="white-space: nowrap;"
                            >
                              <em>{{ item.external_product.brand }}</em>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    color="orange"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-alert
                                  </v-icon>
                                </template>
                                <span>Valeur non mappée</span>
                              </v-tooltip>
                            </span>
                          </template>
                        </td>
                      </tr>
                      <tr v-show="showAdvancedDescription(item.id)">
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.model') }}</td>
                        <td>{{ item.external_product.model }}</td>
                      </tr>
                      <tr v-show="showAdvancedDescription(item.id)">
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.condition') }}</td>
                        <td>
                          <template v-if="item.external_product.condition">
                            <em v-if="!item.provider.has_external_mapping">
                              {{ item.external_product.condition }}
                            </em>
                            <span
                              v-else-if="item.mapping.taxonomy[item.external_product.condition]?.internal_id"
                              style="white-space: nowrap;"
                            >
                              {{ item.mapping.taxonomy[item.external_product.condition].internal_name }}
                              <v-icon
                                color="green"
                                small
                              >
                                mdi-check
                              </v-icon>
                            </span>
                            <span
                              v-else
                              style="white-space: nowrap;"
                            >
                              <em>{{ item.external_product.condition }}</em>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    color="orange"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-alert
                                  </v-icon>
                                </template>
                                <span>Valeur non mappée</span>
                              </v-tooltip>
                            </span>
                          </template>
                        </td>
                      </tr>
                      <tr v-show="showAdvancedDescription(item.id)">
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.color') }}</td>
                        <td>
                          <div
                            v-for="(color, index) in item.external_product.color"
                            :key="index"
                          >
                            <em v-if="!item.provider.has_external_mapping">
                              {{ color }}
                            </em>
                            <span v-else-if="item.mapping.taxonomy[color]?.internal_id">
                              {{ item.mapping.taxonomy[color].internal_name }}
                              <v-icon
                                color="green"
                                small
                              >
                                mdi-check
                              </v-icon>
                            </span>
                            <span v-else>
                              <em>{{ color }}</em>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    color="orange"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-alert
                                  </v-icon>
                                </template>
                                <span>Valeur non mappée</span>
                              </v-tooltip>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr v-show="showAdvancedDescription(item.id)">
                        <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.material') }}</td>
                        <td>
                          <div
                            v-for="(material, index) in item.external_product.material"
                            :key="index"
                          >
                            <em v-if="!item.provider.has_external_mapping">
                              {{ material }}
                            </em>
                            <span v-else-if="item.mapping.taxonomy[material]?.internal_id">
                              {{ item.mapping.taxonomy[material].internal_name }}
                              <v-icon
                                color="green"
                                small
                              >
                                mdi-check
                              </v-icon>
                            </span>
                            <span v-else>
                              <em>{{ material }}</em>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    color="orange"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-alert
                                  </v-icon>
                                </template>
                                <span>Valeur non mappée</span>
                              </v-tooltip>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr v-show="showAdvancedDescription(item.id)">
                        <td>
                          {{ $t('views.external_catalog.synchro_lines.forms.labels.title') }}
                        </td>
                        <td class="long-text">
                          {{ item.external_product.title }}
                        </td>
                      </tr>
                      <tr v-show="showAdvancedDescription(item.id)">
                        <td>
                          {{ $t('views.external_catalog.synchro_lines.forms.labels.description') }}
                        </td>
                        <td class="long-text">
                          {{ item.external_product.description }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <v-row>
                    <v-col class="text-right">
                      <a
                        href="#"
                        @click.prevent="toggleAdvancedDescription(item.id)"
                      >
                        <template v-if="!showAdvancedDescription(item.id)">
                          {{ $t('views.external_catalog.synchro_lines.forms.labels.more') }}
                        </template>
                        <template v-else>
                          {{ $t('views.external_catalog.synchro_lines.forms.labels.less') }}
                        </template>
                      </a>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
      <Snackbar />
    </template>
  </Page>
</template>

<script>
import DropsSelector from '@/components/base/Product/Input/DropsSelector.vue'
import { fetchAllHydra } from '@/helpers/rest'
import Filters from '@/views/ExternalCatalog/SynchroLines/Filters.vue'
import { getQueryString } from '@/helpers/queryString'
import Page from '@/components/core/Page.vue'
import Snackbar from '@/components/core/Snackbar.vue'
import snackbarMixin from '@/mixins/snackbar'

const itemsPerPage = 30

export default {
  name: 'ExternalCatalogSynchroLinesList',
  components: { Page, DropsSelector, Filters, Snackbar },
  mixins: [snackbarMixin],
  data() {
    const initialFilters = {
      provider: this.$route.query.provider,
      query: this.$route.query.query,
      'store[]': [].concat(this.$route.query['store[]']),
      'category[]': [].concat(this.$route.query['category[]']),
      import_status: this.$route.query.import_status,
      'brand[]': [].concat(this.$route.query['brand[]']),
      condition: this.$route.query.condition,
      'price[min]': this.$route.query['price[min]'],
      'price[max]': this.$route.query['price[max]'],
      stock_only: this.$route.query.stock_only,
    }

    return {
      directToCuration: false,
      importLoading: false,
      deleteLoading: false,
      importDialog: false,
      deleteDialog: false,
      selectAll: false,
      selectionOverriden: { },
      checkItems: [],
      table: {
        loading: false,
        options: {
          page: 1,
          itemsPerPage,
          sortBy: [],
          sortDesc: [],
        },
        footerProps: {
          'items-per-page-options': [30],
          'show-first-last-page': true,
        },
      },
      items: [],
      totalItems : 0,
      filters: initialFilters,
      initialFilters: initialFilters,
      advancedDescription: {},
      dropIds: [],
    }
  },
  computed: {
    queryString: function () {
      let filters = {
        ...this.filters,
        'quantity[min]': this.filters.stockOnly ? 1 : 0,
      }
      delete filters.stockOnly

      return 'external_catalog/synchro_lines?' + getQueryString(
        this.table.options.page,
        filters,
        this.table.options.sortBy,
        this.table.options.sortDesc,
        this.table.options.itemsPerPage
      )
    },
    nbSelectedProducts: function() {
      if (!this.selectAll) {
        return Object.values(this.selectionOverriden).filter(v => !!v).length
      } else {
        return this.totalItems - Object.values(this.selectionOverriden).filter(v => !v).length
      }
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler() {
        this.load()
      },
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    async fetchAllSynchroLines() {
      const [basePath, queryString] = this.queryString.split('?', 2)
      const searchParams = new URLSearchParams(queryString)
      searchParams.set('page', '0')
      searchParams.set('itemsPerPage', '1000')
      searchParams.set('properties[]', 'id')

      return (await fetchAllHydra(this.$axios, basePath + '?' + searchParams.toString())).map(item => item.id)
    },
    async importSelection() {
      this.importLoading = true
      this.importDialog = false
      this.$axios.post(
        'external_catalog/synchro_lines/import',
        {
          ids: await this.getSelectedSynchroLines(),
          dropIds: this.dropIds,
          directToCuration: this.directToCuration,
        }
      )
        .then((response) => {
          this.snackbarSuccess(
            this.$i18n.t('views.external_catalog.synchro_lines.forms.message.import.success', { ...response.data })
          )
          this.resetSelection()
          this.table.loading = false
          this.importLoading = false
          this.load()
        })
        .catch((error) => {
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbarError(
            this.$i18n.t('views.external_catalog.synchro_lines.forms.message.import.failed', { error: error_message })
          )
          this.table.loading = false
          this.importLoading = false
          this.load()
        })

    },
    async deleteSelection() {
      this.deleteLoading = true
      this.deleteDialog = false
      this.$axios.post(
        'external_catalog/synchro_lines/delete',
        {
          ids: await this.getSelectedSynchroLines(),
        }
      )
        .then((response) => {
          this.snackbarSuccess(
            this.$i18n.t('views.external_catalog.synchro_lines.forms.message.deletion.success', { ...response.data })
          )
          this.resetSelection()
          this.table.loading = false
          this.deleteLoading = false
          this.load()
        })
        .catch((error) => {
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbarError(
            this.$i18n.t('views.external_catalog.synchro_lines.forms.message.deletion.failed', { error: error_message })
          )
          this.table.loading = false
          this.deleteLoading = false
          this.load()
        })
    },
    async getSelectedSynchroLines() {
      let selectedSynchroLines = []
      if (!this.selectAll) {
        selectedSynchroLines = Object.keys(this.selectionOverriden)
          .filter(key => this.selectionOverriden[key] === true)
      } else {
        const synchroLinesToExclude = Object.keys(this.selectionOverriden)
          .filter(key => this.selectionOverriden[key] === false)

        const allSynchroLines = await this.fetchAllSynchroLines()
        selectedSynchroLines = allSynchroLines.filter(v => !synchroLinesToExclude.includes(v))
      }

      return selectedSynchroLines
    },
    checkSelectAll(checked) {
      this.selectAll = checked
      this.selectionOverriden = {}
      if (checked) {
        const tmp = {}
        for (const item of this.items) {
          tmp[item.id] = true
        }
        this.selectionOverriden = tmp
      }
    },
    showAdvancedDescription(itemId) {
      return !!this.advancedDescription[itemId]
    },
    toggleAdvancedDescription(itemId) {
      this.$set(this.advancedDescription, itemId,  !this.advancedDescription[itemId])
    },
    onFiltersChanged(filters) {
      this.resetSelection()
      this.filters = filters
      this.$router.replace({ name: 'ExternalCatalogSynchroLinesList', query: this.filters })
    },
    cancel() {
      this.importDialog = false
      this.deleteDialog = false
    },
    load() {
      this.table.loading = true
      this.items = []

      this.$axios.get(this.queryString)
        .then((response) => {
          this.table.loading = false
          const items = response.data['hydra:member']
          items.map(function(item) {
            if (!Array.isArray(item.external_product.color)) {
              item.external_product.color = [item.external_product.color].filter(v => v)
            }
            if (!Array.isArray(item.external_product.material)) {
              item.external_product.material = [item.external_product.material].filter(v => v)
            }
            const mappings = {
              taxonomy: {},
              category: {},
            }

            for (const mapping of item.mapping) {
              mappings[mapping.type][mapping.external_value] = mapping.classification
            }
            item.mapping = mappings
          })
          this.items = items

          this.totalItems = response.data['hydra:totalItems']

          if (this.selectAll) {
            const newSelectionOverriden = {}
            for (const p in this.selectionOverriden) {
              if (this.selectionOverriden[p] === false) {
                newSelectionOverriden[p] = false
              }
            }
            for (var item of this.items) {
              if (typeof newSelectionOverriden[item.id] === 'undefined') {
                newSelectionOverriden[item.id] = true
              }
            }
            this.selectionOverriden = newSelectionOverriden
          }
        })
        .catch((error) => {
          this.table.loading = false
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
    statusColor(item) {
      switch (item.import_status) {
      case 'pending':
        return 'teal lighten-2'
      case 'done':
        return 'light-green darken-3'
      case 'error':
        return 'red lighten-3'
      case 'none':
      default:
        return 'grey lighten-1'
      }
    },
    resetSelection() {
      this.selectAll = false
      this.selectionOverriden = {}
    },
  },
}
</script>

<style scoped>
.image-wrapper {
  position: relative;
}
.product-title {
  background-color: rgba(0,0,0,0.4);
  bottom: 0;
  position: absolute;
  width: 100%;
}

.long-text {
  max-width: 5px; /* arbitrary value to force width calculation */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.long-text:hover {
  cursor: pointer;
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

</style>
